.img-pro__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro__wrp-pr-txt {
  margin-top: 5vh;
  font-size: 23px;
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro__wrp-imgs {
  position: relative;
}

.img-pro__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 6%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.img-pro__wrp-load-inner-img {
  position: absolute;
  margin-top: 6%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro__wrp-tht-txt {
  color: white;
  margin-top: 11vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.5vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.img-pro__wrp-shirt {
  position: relative;
}

.img-pro__wrp-shirt__img {
  position: absolute;
  justify-content: center;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  align-items: center;
}

.img-pro__wrp-2700-txt {
  color: #ffffff;
  margin-top: 32vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
