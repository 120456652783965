:root {
  --white: #ffffff;
  --headline-copy: #292a2d;
  --success-green: #48bf59;
}

.acc-scan {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.accbutton-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.accbutton-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.accbutton-pg__bg-dgn {
  width: 100%;
  height: 100%;
}
.accbutton__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-left: 50px;
  margin-top: 30px;
  border-radius: 22.5px;
  background-color: #d3d3d3;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
.accbutton-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.accbutton-pg__cnt-wrp {
  height: 58px;
  margin-top: 5.9vh;
}

.accbutton-pg__cnt-wrp > p {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #292a2d;
}

.accbutton__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-left: 40px;
  margin-top: 20px;
  border-radius: 22.5px;
  background-color: #d3d3d3;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
.acc-scan__bg-success {
  background-color: #48bf59;
}

.acc-scan__bg-no-success {
  background-color: #b5dfc2;
}

.acc-scan__vl {
  border-left: 6px dashed white;
  height: 100vh;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
  z-index: 2;
}

.acc-scan__hr {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.acc-scan__cnt {
  position: relative;
  width: 100%;
  height: 100%;
}

.acc-scan__top-sec {
  text-align: center;
  padding-top: 11.2vh;
}

.acc-scan__ph-setup {
  height: 42px;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--headline-copy);
}

.acc-scan__ph-finst {
  margin-top: 2.6vh;
  height: 100px;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.acc-scan__mid-sec {
  position: absolute;
  width: 100%;
  top: 43%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc-scan__mid-sec-su {
  position: absolute;
  width: 100%;
  top: 43%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc-scan__tar-rect {
  width: 100px;
  height: 100px;
  margin: auto;
  border: solid 3px var(--white);
  background-color: var(--success-green);
}

.acc-scan__tar-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.acc-scan__tar-cir {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: solid 4px var(--white);
  background-color: var(--success-green);
}

.acc-scan__tar-success {
  height: 45px;
  margin-top: 11px;
  font-size: 37.5px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  margin-bottom: 2vh;
}

.acc-scan__angle-sec {
  position: absolute;
  width: 100vw;
  top: 68%;
}

.acc-scan__ph-angle {
  height: 100px;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.acc-scan__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-scan__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-scan__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 2;
  background-color: white;
  border-radius: 50%;
}

.acc-scan__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-scan__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
