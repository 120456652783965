.signin-fd-pg {
  height: 100vh;
  width: 100vw;
}

.signin-fd-pg__qa-wrp {
  margin-top: 5.3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signin-srn__wr {
  width: 100%;
  height: 100vh;
}
.signin-fd-pg__qa-wrp .rpwd-fd-pg_hde-pwd {
  height: 19.7px;
  width: 20px;
  z-index: 20;
  right: 28px;
  position: absolute;
  top: 20px;
  cursor: pointer;
}
.signin-srn__cnt {
  margin-top: calc(43.1 - 302px);
}

.signin-srn__wh-st {
  width: 365px;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: calc(100% - 300px);
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__hlptxt {
  width: 365px;
  height: 29px;
  font-size: 15px;
  font-weight: 250;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: Lato-Regular;
  margin-top: calc(100% - 365px);
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__ipt {
  width: 325px;
  outline: none;
  border: none;
  /* height: 1px; */
  /* margin: 3.5px 25px 135.5px 25px; */
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  margin-top: 30px;
  margin-left: 25px;
  font-size: 22px;
  font-weight: 500;
  font-family: Lato;
  color: #606060;
  text-align: center;
}

.signin-srn__ln-ipt {
  width: 325px;
  outline: none;
  border: none;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  margin-top: 30px;
  margin-left: 25px;
  font-size: 22px;
  font-weight: 500;
  font-family: Lato-Regular;
  color: #606060;
  text-align: center;
}

.signin-srn__pwd-hlptxt {
  width: 365px;
  height: 29px;
  font-size: 15px;
  font-weight: 250;
  font-stretch: normal;
  font-style: normal;
  font-family: Lato-Regular;
  line-height: normal;
  margin-top: calc(100% - 365px);
  margin-bottom: 10px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__btns {
  display: flex;
  flex-direction: row;
  margin-top: calc(70.1% - 160px);
}

.signin-srn__fp {
  width: 66px;
  height: 30px;
  margin-top: 19px;
  font-size: 19px;
  font-weight: 800;
  font-stretch: normal;
  font-family: Lato;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
  cursor: pointer;
}
.signin__lnks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(50px);
}

.signin__sign-in {
  width: 300px;
  height: 45px;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}
.signin__sign-in:disabled {
  background-color: #000000;
  opacity: 0.5;
}

.signin__sign-in:active {
  position: relative;
  top: 1px;
}

.signin__sign-up {
  width: 70px;
  height: 22px;
  margin-top: 19px;
  font-size: 18px;
  font-weight: 800;
  font-family: Lato;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.signin-fd-pg__qs-wh {
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 24px;
}

.signin-fd-pg__fs-nm,
.signin-fd-pg__ls-nm {
  border: none;
  outline: none;
  text-align: center;
  font-size: 22px;
  height: 32px;
  color: #606060;
  width: 325px;
  margin: 13px 25px 4.5px;
  border-bottom: solid 0.5px #292a2d;
}

.signin-fd-pg__fs-tl,
.signin-fd-pg__ls-tl {
  width: 274px;
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.signin-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(16.6vh - 37px);
}
