.back-btn, 
.continue-btn, 
.edit-btn,
.save-btn {
    width: 159px;
    height: 45px;
    border-radius: 22.5px;
    background-color: #000000;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
}
.back-btn1,
.continue-btn1 {
  width: 36vw;
  height: 5vh;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.continue-btn:disabled {
    opacity: 0.5;
}
.continue-btn1:disabled {
    opacity: 0.5;
}
.save-btn {
    width: 159.5px;
    background-color: #48bf59;
}

.back-btn:active, 
.continue-btn:active, 
.edit-btn:active,
.save-btn:active {
    position: relative;
    top: 0.75px;
}