/* Global Styles */

/* Google Fonts -> Lato */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
div {
  -webkit-overflow-scrolling: touch;
}

html{
  height: 100%;
}

body .nme-fd-pg, body .eml-fd-pg__wrp, 
body .cnp-pg__wrp.page-field, body .rpwd-fd-pg__wrp, body .signin-fd-pg, body .auth-pg__wrp{
  position: fixed;
  height: 100%;
  overflow: hidden;
}
