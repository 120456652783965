.vck-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url("../../assets/images/bg-blur.jpg");
  background-size: cover;
}

.vck-pg__btn-wrp {
  padding-top: 33px;
  display: flex;
  cursor: pointer;
}

.vck-pg__lft-arrow {
  height: 18px;
  width: 14px;
  margin-left: 24px;
  margin-right: 3px;
  margin-top: 1.5px;
}

.vck-pg__back-btn {
  width: 45px;
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
}

.vch-pg__instrct-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.vck-pg__ado-inst {
  height: 42px;
  margin-top: calc(11.2vh - 51.4px);
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.vck-pg__inc-vol-inst {
  margin-top: 20px;
  width: 325px;
  height: 128px;
  font-size: 23px;
  font-weight: 350;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.vck-pg__vl-ch-wrp {
  object-fit: contain;
  margin-top: 5.6vh;
}

.vck-pg__vl-ch-ig {
  width: 155px;
  height: 194px;
}

.vck-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.vck-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vck-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.vck-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.vck-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
