.auth-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.auth-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.auth-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.auth-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.auth-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.auth-pg__wh-qs {
  margin-top: 3.2vh;
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.auth-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 4px;
}

.auth-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.auth-pg__snd-ncd {
  margin-top: 14.5px;
  cursor: pointer;
  text-decoration: underline;
  height: 32px;
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.auth-pg__lnks-wrp {
  margin-top: 23.4vh;
}

.auth-pg__snd-rse-btn {
  width: 300px;
  height: 45px;
  color: white;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.auth-pg__snd-rse-btn:disabled {
  background-color: #000000;
  opacity: 0.5;
}

.auth-pg__snd-rse-btn:active {
  position: relative;
  top: 0;
}

.auth-pg__cnt-wrp .__react_component_tooltip.show {
  padding: 11.2px 14px 12px 20px;
}
