@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
/* Global Styles */

/* Google Fonts -> Lato */

*,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
div {
  -webkit-overflow-scrolling: touch;
}

html{
  height: 100%;
}

body .nme-fd-pg, body .eml-fd-pg__wrp, 
body .cnp-pg__wrp.page-field, body .rpwd-fd-pg__wrp, body .signin-fd-pg, body .auth-pg__wrp{
  position: fixed;
  height: 100%;
  overflow: hidden;
}


.splash {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.splash-cnt {
    background-image: url(/static/media/splash.4e7059ad.jpg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    margin: auto;
}

.splash-logo {
    margin-top: 41.2vh;
    width: 252px;
    height: 123px;
}
.home__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.home__pg-cnt {
  display: flex;
  flex-direction: column;
}

.home__pg-bg-sec {
  height: 37.2vh;
  position: relative;
}

.home__pg-bg-top-dgn {
  width: 100%;
  height: 100%;
}

.home__pg-bg-instructs {
  position: absolute;
  top: 24.8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.home__pg-bg-hey {
  width: 276px;
  height: 54px;
  text-shadow: 0 2px 4px rgba(0, 7, 49, 0.5);
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.home__pg-bg-shop {
  width: 325px;
  height: 160px;
  margin: 10px 0 0;
  text-shadow: 0 1px 4px rgba(0, 7, 49, 0.5);
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.home__pg-three-min {
  position: absolute;
  top: calc(100vh - 69vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 64px;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #292a2d;
}

.home__pg-things-sec {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  top: 44vh;
}

.home__pg-abt-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
}

.home__pg-dgn-oval {
  display: inline-block;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 9px 0 rgba(173, 173, 173, 0.27);
  background-color: white;
}

.home__pg-shirt-ico {
  width: 34px;
  height: 34px;
  object-fit: contain;
}

.home__pg-abt-cnt {
  display: inline-block;
  width: 211px;
  height: 52px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #606060;
}

.home__pg-reduce-ico {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.home__pg-heart-ico {
  width: 33px;
  height: 30px;
  object-fit: contain;
}

.home__pg-btm-sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-bottom: 2.3vh;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.home__pg-get-strt:active {
  position: relative;
  top: 0.8px;
}

.home__pg-sgn-in {
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 6.9vh;
  height: 22px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.nme-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.nme-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.nme-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.nme-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.nme-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}
.nme-pg__qa-wrp {
  margin-top: 4vh;
}
.nme-pg__wh-qs {
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.nme-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.nme-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.nme-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.nme-pg__dot,
.nme-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.nme-pg__act-dot {
  background-color: #000000;
}

.back-btn, 
.continue-btn, 
.edit-btn,
.save-btn {
    width: 159px;
    height: 45px;
    border-radius: 22.5px;
    background-color: #000000;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
}
.back-btn1,
.continue-btn1 {
  width: 36vw;
  height: 5vh;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.continue-btn:disabled {
    opacity: 0.5;
}
.continue-btn1:disabled {
    opacity: 0.5;
}
.save-btn {
    width: 159.5px;
    background-color: #48bf59;
}

.back-btn:active, 
.continue-btn:active, 
.edit-btn:active,
.save-btn:active {
    position: relative;
    top: 0.75px;
}
.eml-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.eml-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.eml-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.eml-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.eml-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.eml-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.eml-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.eml-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.eml-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.eml-pg__dot,
.eml-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.eml-pg__act-dot {
  background-color: #000;
}

.pwd-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.pwd-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.pwd-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.pwd-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.pwd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.pwd-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.pwd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.pwd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.pwd-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.pwd-pg__dot,
.pwd-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.pwd-pg__act-dot {
  background-color: #000;
}

.rpwd-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.rpwd-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.rpwd-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.rpwd-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.rpwd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.rpwd-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.rpwd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.rpwd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.rpwd-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.rpwd-pg__dot,
.rpwd-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.rpwd-pg__act-dot {
  background-color: #000;
}

.hbi-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.hbi-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.hbi-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.hbi-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.hbi-pg__cnt-wrp {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 27.8vh;
}

.hbi-pg__wh-qs {
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

select {
  -webkit-appearance: none;
          appearance: none;
}

.hbi-pg__slt-ipt {
  outline: none;
  border: none;
  border-bottom: solid 0.5px #292a2d;
  width: 94px;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 1px;
  text-align: center;
  color: #606060;
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
}

.hbi-pg__an-dt {
  width: 325px;
  height: 54px;
  outline: none;
  border: none;
  border-bottom: solid 0.5px #292a2d;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  padding-left: 60px;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.hbi-pg__cur-st-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hbi-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10vh;
}

.hbi-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.hbi-pg__dot,
.hbi-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.hbi-pg__act-dot {
  background-color: #000;
}

.gnd-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.gnd-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.gnd-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.gnd-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.gnd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 27.8vh;
}

.gnd-pg__wh-qs {
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.gnd-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 4px;
}

.gnd-pg__an-wp {
  text-align: center;
}

.gnd-pg__an-wp > p > label {
  border: none;
  outline: none;
  background-color: transparent;
  width: 300px;
  height: 45px;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: #606060;
  /* padding: 5px 0; */
}

.gnd-pg__an-wp > p {
  margin-bottom: 4.2vh;
}

.gnd-pg__an-wp > p:last-child {
  margin-bottom: 0;
}

input[type="radio"] {
  display: none;
}

.gnd-pg__an-wp > p > label:first-child {
  margin-top: 0;
}

input[type="radio"]:checked + label {
  background-color: #48bf59;
  color: white;
  border-radius: 22.5px;
  display: inline-block;
}

.gnd-pg__cur-st-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gnd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10vh;
}

.gnd-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.gnd-pg__dot,
.gnd-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.gnd-pg__act-dot {
  background-color: #000;
}

.trm-cndn__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
}

.trm-cndn__wrp-pg {
  display: flex;
  flex-direction: column;
}
.trm-cndn__wrp-img__cls {
  width: 16px;
  height: 16px;
  margin: 0 0 9.5vh 83vw;
  object-fit: contain;
}
.trm-cndn__wrp-head {
  width: 345px;
  height: 29px;
  margin-top: -2vh;
  margin-bottom: 7vh;
  font-family: Roboto, arial, sans-serif;
  color: gray;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
}
.trm-cndn__txt {
  margin-bottom: 13px;
  padding-left: 3vw;
}
.trm-cndn__hd {
  margin-top: 20px;
  color: gray;
  font-family: Lato;
  font-size: 2vh;
  padding-left: 3vw;
  font-weight: 800;
}
.trm-cndn__lb {
  margin-top: 20px;
  color: gray;
  font-family: Lato;
  font-size: 2vh;
  font-weight: 800;
}
.trm-cndn__af-hd {
  margin-top: 15px;
  padding-left: 3vw;
  font-size: 19px;
}
.trm-cndn__wrp-scrl {
  width: calc(100vw - 30px);
  height: 80vh;
  margin: 30px 15px;
  padding: 24px 0 56px;
  border-radius: 19px;
  box-shadow: 0 2px 11px 0 rgba(0, 7, 49, 0.5);
  background-color: var(--white);
  overflow-x: hidden;
  overflow-y: scroll;
}

.trm-cndn__wrp-plcy__prgh {
  margin-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  letter-spacing: 2;
  color: gray;
  font-weight: 400;
  font-size: 14px;
  padding-left: 5px;
}

.trm-cndn__wrp-plcy__etta {
  margin-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  letter-spacing: 2;
  color: gray;
  font-weight: 400;
  font-size: 14px;
  padding-left: 4px;
}
.trm-cndn__check {
  margin-top: 20px;
  font-family: Roboto, arial, sans-serif;
  color: gray;
  font-size: 20px;
  padding-left: 3vw;
}
.trm-cndn__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0vh;
}

.trm-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.trm-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trm-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.trm-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.trm-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.auth-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.auth-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.auth-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.auth-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.auth-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.auth-pg__wh-qs {
  margin-top: 3.2vh;
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.auth-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 4px;
}

.auth-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.auth-pg__snd-ncd {
  margin-top: 14.5px;
  cursor: pointer;
  text-decoration: underline;
  height: 32px;
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.auth-pg__lnks-wrp {
  margin-top: 23.4vh;
}

.auth-pg__snd-rse-btn {
  width: 300px;
  height: 45px;
  color: white;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.auth-pg__snd-rse-btn:disabled {
  background-color: #000000;
  opacity: 0.5;
}

.auth-pg__snd-rse-btn:active {
  position: relative;
  top: 0;
}

.auth-pg__cnt-wrp .__react_component_tooltip.show {
  padding: 11.2px 14px 12px 20px;
}

.auth-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.auth-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.auth-pg__wh-qs {
  margin-top: 8vh;
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.auth-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.auth-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.auth-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 4px;
}

.auth-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.auth-pg__snd-ncd {
  margin-top: 14.5px;
  cursor: pointer;
  text-decoration: underline;
  height: 32px;
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.auth-pg__lnks-wrp {
  margin-top: 8vh;
}

.auth-pg__snd-rse-btn {
  width: 300px;
  height: 45px;
  color: white;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.auth-pg__snd-rse-btn:disabled {
  background-color: #000000;
  opacity: 0.5;
}

.auth-pg__snd-rse-btn:active {
  position: relative;
  top: 0;
}

.auth-pg__cnt-wrp .__react_component_tooltip.show {
  padding: 11.2px 14px 12px 20px;
}

.nme-fd-pg {
  height: 50vh;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
}

.nme-fd-pg__qa-wrp {
  margin-top: 10vh;
  /* margin-top: 26vh; */
  /* margin-bottom: 83vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nme-fd-pg__qs-wh {
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.nme-fd-pg__fs-nm,
.nme-fd-pg__ls-nm {
  border: none;
  outline: none;
  text-align: center;
  font-size: 22px;
  height: 32px;
  color: #606060;
  width: 325px;
  height: 60px;
  border-bottom: solid 0.5px #292a2d;
}

.nme-fd-pg__ls-nm {
  height: 49.5px;
}

.nme-fd-pg__fs-tl,
.nme-fd-pg__ls-tl {
  width: 274px;
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.nme-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 93.5px; */
  margin-top: 50px;
}

.eml-fd-pg__wrp {
  width: 100vw;
  height: 100%;
  position: relative;
}

.eml-fd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.eml-fd-pg__wh-qs {
  margin-top: 10vh;
  /* margin-top: 150px; */
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.eml-fd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  border-bottom: solid 0.5px #292a2d;
  height: 53.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.eml-fd-pg__pwd-tl {
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.eml-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: calc(190px - 36px - 50px); */
  margin-top: calc(190px - 55px - 50px);
}

.rpwd-fd-pg__wrp {
  width: 100vw;
  height: 100%;
  position: relative;
}

.rpwd-fd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
  /* margin-top: 140px; */
  margin-top: 19vh;
}

.pwd-fd-pg__qa-wrp {
  margin-top: 26vh;
  /* margin-bottom: 83vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rpwd-fd-pg__wh-qs {
  width: 100%;
  height: 39px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.rpwd-fd-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  z-index: 20;
  margin-bottom: 10px;
}

.rpwd-fd-pg_ipt-blk {
  position: relative;
}

.rpwd-fd-pg_hde-pwd {
  height: 19.7px;
  width: 20px;
  z-index: 20;
  right: 4px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

.rpwd-fd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  border-bottom: solid 0.5px #292a2d;
  height: 53.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.rpwd-fd-pg__pwd-tl {
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.rpwd-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.cnp-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.cnp-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.cnp-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.cnp-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.cnp-pg__pop-up > p {
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #292a2d;
}

.cnp-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.cnp-pg__pop-wrap {
  position: relative;
}

.cnp-pg__pop-up {
  width: 224px;
  height: 120.9px;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #e0e0e0;
  background-color: #fffffe;
  position: absolute;
  border-radius: 5px;
  top: -135px;
  left: -80px;
}

.cnp-pg__pop-up::after {
  position: absolute;
  right: 10%;
  bottom: -7px;
  width: 0;
  height: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e0e0ff;
  z-index: 3;
}

.cnp-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.cnp-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.cnp-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
  margin-top: 2vh;
}

.cnp-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #d8d8d8;
  background-color: #fffffe;
  border-radius: 5px;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  right: 5px !important;
  left: auto !important;
}

.__react_component_tooltip .tooltip-info p,
.gnd-pg__wh-qs .tooltip-info p {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 400 !important;
}

.tooltip-info p:last-child {
  margin-bottom: 0px;
}

.cnp-pg__info-alt:hover {
  cursor: pointer;
}

.cnp-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.cnp-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.cnp-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.cnp-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.cnp-pg__pop-up > p {
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #292a2d;
}

.cnp-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.cnp-pg__cnt-wrp.margin-top-field {
  /* margin-top: 140px; */
  margin-top: 19vh;
}

.cnp-pg__pop-wrap {
  position: relative;
}

.cnp-pg__pop-up {
  width: 224px;
  height: 120.9px;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #e0e0e0;
  background-color: #fffffe;
  position: absolute;
  border-radius: 5px;
  top: -135px;
  left: -80px;
}

.cnp-pg__pop-up::after {
  position: absolute;
  right: 10%;
  bottom: -7px;
  width: 0;
  height: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e0e0ff;
  z-index: 3;
}

.cnp-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  /* margin-bottom: 18px; */
  margin-bottom: 0px;
}

.cnp-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.cnp-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.page-field .cnp-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 15vh; */
  margin-top: 5vh;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #d8d8d8;
  background-color: #fffffe;
  border-radius: 5px;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  right: 5px !important;
  left: auto !important;
}

.tooltip-info p {
  text-align: left;
  margin-bottom: 10px;
}

.tooltip-info p:last-child {
  margin-bottom: 0px;
}

.cnp-pg__info-alt:hover {
  cursor: pointer;
}

.re-np-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.re-np-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.re-np-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.re-np-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.re-np-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.re-np-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.re-np-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.re-np-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.re-np-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}

.sg-in-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.sg-in-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.sg-in-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.sg-in-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.sg-in-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.sg-in-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.sg-in-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.sg-in-pg__lnks-wrp {
  margin-top: 17.2vh;
}

.sg-in-pg__snd-rse-btn {
  width: 300px;
  height: 45px;
  color: white;
  opacity: 0.5;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.sg-in-pg__snd-rse-btn:active {
  position: relative;
  top: 0;
}

.sg-in-pg__cnl-btn {
  margin-top: 19px;
  font-size: 18px;
  font-weight: 800;
  text-decoration: underline;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.fp-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.fp-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.fp-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.fp-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.fp-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.fp-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.fp-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.fp-pg__lnks-wrp {
  margin-top: 17.2vh;
}

.fp-pg__snd-rse-btn {
  width: 300px;
  height: 45px;
  color: white;

  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.fp-pg__snd-rse-btn:disabled {
  background-color: #000000;
  opacity: 0.5;
}
.fp-pg__snd-rse-btn:active {
  position: relative;
  top: 0;
}

.fp-pg__cnl-btn {
  margin-top: 19px;
  font-size: 18px;
  font-weight: 800;
  text-decoration: underline;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.fpr-pg__wrp {
    width: 100vw;
    height: 100vh;
}

.fpr-pg__dgn-wrp {
    height: 37.2vh;
    position: relative;
}

.fpr-pg__bg-dgn {
    width: 100%;
    height: 100%;
}

.fpr-pg__logo {
    position: absolute;
    top: calc((32.7vh/2) - (71px/2));
    left: calc(50vw - (132px /2));
}

.fpr-pg__cnt-wrp {
     height: 58px;
     margin-top: 5.9vh;
}

.fpr-pg__cnt-wrp > p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #292a2d;
}
.signin-fd-pg {
  height: 100vh;
  width: 100vw;
}

.signin-fd-pg__qa-wrp {
  margin-top: 5.3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signin-srn__wr {
  width: 100%;
  height: 100vh;
}
.signin-fd-pg__qa-wrp .rpwd-fd-pg_hde-pwd {
  height: 19.7px;
  width: 20px;
  z-index: 20;
  right: 28px;
  position: absolute;
  top: 20px;
  cursor: pointer;
}
.signin-srn__cnt {
  margin-top: calc(43.1 - 302px);
}

.signin-srn__wh-st {
  width: 365px;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: calc(100% - 300px);
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__hlptxt {
  width: 365px;
  height: 29px;
  font-size: 15px;
  font-weight: 250;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: Lato-Regular;
  margin-top: calc(100% - 365px);
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__ipt {
  width: 325px;
  outline: none;
  border: none;
  /* height: 1px; */
  /* margin: 3.5px 25px 135.5px 25px; */
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  margin-top: 30px;
  margin-left: 25px;
  font-size: 22px;
  font-weight: 500;
  font-family: Lato;
  color: #606060;
  text-align: center;
}

.signin-srn__ln-ipt {
  width: 325px;
  outline: none;
  border: none;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  margin-top: 30px;
  margin-left: 25px;
  font-size: 22px;
  font-weight: 500;
  font-family: Lato-Regular;
  color: #606060;
  text-align: center;
}

.signin-srn__pwd-hlptxt {
  width: 365px;
  height: 29px;
  font-size: 15px;
  font-weight: 250;
  font-stretch: normal;
  font-style: normal;
  font-family: Lato-Regular;
  line-height: normal;
  margin-top: calc(100% - 365px);
  margin-bottom: 10px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.signin-srn__btns {
  display: flex;
  flex-direction: row;
  margin-top: calc(70.1% - 160px);
}

.signin-srn__fp {
  width: 66px;
  height: 30px;
  margin-top: 19px;
  font-size: 19px;
  font-weight: 800;
  font-stretch: normal;
  font-family: Lato;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
  cursor: pointer;
}
.signin__lnks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(50px);
}

.signin__sign-in {
  width: 300px;
  height: 45px;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}
.signin__sign-in:disabled {
  background-color: #000000;
  opacity: 0.5;
}

.signin__sign-in:active {
  position: relative;
  top: 1px;
}

.signin__sign-up {
  width: 70px;
  height: 22px;
  margin-top: 19px;
  font-size: 18px;
  font-weight: 800;
  font-family: Lato;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.signin-fd-pg__qs-wh {
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 24px;
}

.signin-fd-pg__fs-nm,
.signin-fd-pg__ls-nm {
  border: none;
  outline: none;
  text-align: center;
  font-size: 22px;
  height: 32px;
  color: #606060;
  width: 325px;
  margin: 13px 25px 4.5px;
  border-bottom: solid 0.5px #292a2d;
}

.signin-fd-pg__fs-tl,
.signin-fd-pg__ls-tl {
  width: 274px;
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.signin-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(16.6vh - 37px);
}


.acc-manage__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.acc-manage__rect-grp {
  width: 16px;
  height: 16px;
  margin: 0 0 9.5vh 83vw;
  object-fit: contain;
}
.acc-manage__rect-my-acc {
  width: 345px;
  height: 29px;
  margin: -50px 5px 32.5px;
  font-family: Lato;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: var(--headline-copy);
}
.acc-manage__rect-edit {
  width: 35vw;
  height: 5vh;
  margin: 35px 1px 0 110px;
  border-radius: 22.5px;
  font-family: Lato;
  color: white;
  font-size: 18px;
  font-weight: 800;
  background-color: #000000;
}
.acc-manage__rect-comm {
  margin-top: 15vh;
  margin-left: 3.3vw;
}
.acc-manage__rect-share {
  width: 39vw;
  height: 45px;
  border-radius: 22.5px;
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  color: white;
  letter-spacing: normal;
  background-color: #000000;
}
.acc-manage__rect-updt {
  width: 39vw;
  height: 45px;
  border-radius: 22.5px;
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  color: white;
  letter-spacing: normal;
  background-color: #000000;
}
.acc-manage__rect-save {
  width: 35vw;
  height: 5vh;
  margin: 35px 1px 0 110px;
  border-radius: 22.5px;
  font-family: Lato;
  color: white;
  font-size: 18px;
  font-weight: 800;
  border: none;
  background-color: #48bf59;
}
.textbox {
  padding: 0;
}
.accmanage-fd-pg__an-em {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-fn {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-ln {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 2px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-he {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 23px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-bd {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 10px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-em {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 31px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-pw {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 2px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect {
  width: calc(100vw - 30px);
  height: 70vh;
  margin: 24px 15px;
  padding: 24px 0 56px;
  border-radius: 19px;
  box-shadow: 0 2px 11px 0 rgba(0, 7, 49, 0.5);
  background-color: var(--white);
}
.acc-manage__rect-det {
  width: 2vw;
  height: 4vh;
  margin: 38.5px 12px 25px 38px;
  font-family: Lato;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: 0.78px;
  color: #606060;
}
.acc-manage-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-manage-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-manage-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.acc-manage-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-manage-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.vck-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url(/static/media/bg-blur.f4a78d48.jpg);
  background-size: cover;
}

.vck-pg__btn-wrp {
  padding-top: 33px;
  display: flex;
  cursor: pointer;
}

.vck-pg__lft-arrow {
  height: 18px;
  width: 14px;
  margin-left: 24px;
  margin-right: 3px;
  margin-top: 1.5px;
}

.vck-pg__back-btn {
  width: 45px;
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
}

.vch-pg__instrct-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.vck-pg__ado-inst {
  height: 42px;
  margin-top: calc(11.2vh - 51.4px);
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.vck-pg__inc-vol-inst {
  margin-top: 20px;
  width: 325px;
  height: 128px;
  font-size: 23px;
  font-weight: 350;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.vck-pg__vl-ch-wrp {
  object-fit: contain;
  margin-top: 5.6vh;
}

.vck-pg__vl-ch-ig {
  width: 155px;
  height: 194px;
}

.vck-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.vck-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vck-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.vck-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.vck-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.qu-expv {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.cam-bxa__cam-sec1 {
  width: 100vw;
  object-fit: cover;
  height: calc(100vh - 29px);
}
.qu-expv__cnt {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #393939;
}

.qu-expv__top-sec {
  height: 65px;
  width: 100%;
  padding-top: 33px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.qu-expv__btn-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qu-expv__lft-ar {
  height: 15px;
  width: 14px;
  margin-left: 1vw;
  margin-right: 0.2vw;
  margin-top: 0.1vh;
}

.qu-expv__rgt-ar {
  margin-left: 0.2vw;
  margin-right: 1vw;
  height: 15px;
  width: 14px;
  text-align: center;
}

.qu-expv__top-sec button {
  outline: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.qu-expv__top-sec button:first-child {
  color: grey;
}

.qu-expv__mid-sec {
  width: 100%;
  height: 50%;
  padding: 0 12.5px;
}

.qu-expv__quick-vid {
  object-fit: cover;
  width: calc(100vw - 25px);
  height: calc(100vh - (29px + 65px));
  border-radius: 55px;
}

.qu-expv__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.qu-expv__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qu-expv__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.qu-expv__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.qu-expv__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.scan-strt {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.scan-strt__cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-image: url(/static/media/peacock-background-lightened.f4a78d48.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.scan-strt__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.scan-strt__btn-wrp {
  height: 51px;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  padding-top: 33px;
}

.scan-strt__lft-ar {
  margin-top: 2px;
  height: 12.6px;
  width: 14px;
  margin-left: 24px;
  margin-right: 2px;
}

.scan-strt__back-btn {
  outline: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.scan-strt__back-btn > label {
  text-align: center;
}

.scan-strt__hw-wrks {
  margin-top: 40px;
  height: 42px;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__hw-spin {
  height: 128px;
  margin-top: 20px;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__mid-sec {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scan-strt__mid-inst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.scan-strt__mid-inst > p:first-child {
  width: 44px;
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 16px;
}

.scan-strt__mid-inst > p:last-child {
  margin-top: 13px;
  width: 64px;
  height: 30px;
  font-size: 25px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__scn-bwrp {
  position: absolute;
  bottom: 11.9vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scan-strt__scan-btn {
  border: none;
  outline: none;
  width: 300px;
  height: 45px;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  cursor: pointer;
}

.scan-strt__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.scan-strt__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-strt__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.scan-strt__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.scan-strt__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cam-bxa__cnt {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__cam-sec {
  width: 100vw;
  object-fit: cover;
  height: calc(100vh - 29px);
}

.cam-bxa__wh-pose-man {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px transparent;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}

.cam-bxa__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.cam-bxa__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cam-bxa__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.cam-bxa__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.pose-est {
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100vh - 29px);
  width: 100vw;
}

.pose-est__wrp {
  height: 100%;
  width: 100%;
  position: relative;
}

.pose-est__webcam {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  width: 100vw;
  height: calc(100vh - 29px);
}
.po-est__vid {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.pose-est__canvas {
  padding: 55px 55px 105px 55px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  width: 100vw;
  height: 90vh;
}

.acc-scan__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-scan__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-scan__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.acc-scan__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-scan__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa__wh-pose-man {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px transparent;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}
.vck-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url(/static/media/bg-blur.f4a78d48.jpg);
  background-size: cover;
}
.cam-bxa__cam-sec {
  width: 100vw;
  object-fit: cover;
  visibility: hidden;
  height: calc(100vh - 29px);
}
.cam-bxa__cam-sec1 {
  width: 100vw;
  object-fit: cover;
  visibility: hidden;
  height: calc(100vh - 29px);
}
.cam-bxa__cam-can {
  width: 10;
  object-fit: cover;
  height: 10;
}
.cam-bxa {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cam-bxa__cnt {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__cam-sec {
  width: 100vw;
  object-fit: cover;
  height: calc(100vh - 29px);
}

.cam-bxa__wh-pose-man {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px transparent;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__bdr-con {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px white;
  width: calc(100vw - 100px);
  height: calc(100vh - 26vh);
}
.cam-bxa__bdr-con1 {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px white;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.cam-bxa__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cam-bxa__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.cam-bxa__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.po-est__vid {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: relative;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}
.gif1 {
  width: 80vw;
  height: 80vh;
  margin-left: 12vw;
}
.gif2 {
  position: fixed;
  background-color: #000419;
  width: 100vw;
  height: 100vh;
}

.lod__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url(/static/media/bg.d6ba5d57.png);
  background-repeat: no-repeat;
  background-size: cover;
}
/* 
.lod__wrp-img {
  width: 100vw;
  height: 100vh;
}

.lod__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.lod__wrp-imgs {
  position: relative;
}

.lod__wrp-load-img {
  position: absolute;
  top: 40%;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}
.lod__wrp-load-inner-img {
  position: absolute;
  margin-top: 10%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.lod__wrp-tht-txt {
  color: white;
  margin-top: 11vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.5vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.lod__wrp-one-txt {
  position: relative;
  color: white;
  margin-top: 8vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.lod__wrp-sus-txt {
  color: white;
  position: relative;
  text-align: center;
  margin-top: 3vh;
  font-weight: 50;
  font-size: 17px;
  font-family: Lato;
  letter-spacing: normal;
}

.lod__wrp-btn {
  position: relative;
  margin-top: 17vh;
}

.lod__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  width: 80%;
  opacity: 0.5;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
} */

.lod-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.lod-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lod-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.lod-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.lod-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.img-pro__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url(/static/media/bg.d6ba5d57.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro__wrp-pr-txt {
  margin-top: 5vh;
  font-size: 23px;
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro__wrp-imgs {
  position: relative;
}

.img-pro__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 6%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.img-pro__wrp-load-inner-img {
  position: absolute;
  margin-top: 6%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro__wrp-tht-txt {
  color: white;
  margin-top: 11vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.5vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.img-pro__wrp-shirt {
  position: relative;
}

.img-pro__wrp-shirt__img {
  position: absolute;
  justify-content: center;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  align-items: center;
}

.img-pro__wrp-2700-txt {
  color: #ffffff;
  margin-top: 32vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.img-pro1__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url(/static/media/bg.d6ba5d57.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro1__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro1__wrp-pr-txt {
  margin-top: 5vh;
  font-size: 23px;
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro1__wrp-imgs {
  position: relative;
}

.img-pro1__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 6%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.img-pro1__wrp-load-inner-img {
  position: absolute;
  margin-top: 6%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro1__wrp-tht-txt {
  color: white;
  margin-top: 10vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.2vh;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1s;
  letter-spacing: 1;
}

.img-pro1__wrp-tub {
  position: relative;
}

.img-pro1__wrp-tub-img {
  position: absolute;
  font-size: 3.3vh;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-family: Lato;
  margin-top: 9%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.img-pro1__wrp-24-btubs {
  font-size: 3.3vh;
  color: #ffffff;
  font-weight: 400;
  font-family: Lato;
  margin-top: 70%;
  text-align: center;
}

.img-pro1__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.img-pro1__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-pro1__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.img-pro1__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.img-pro1__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.img-pro2__wrp {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url(/static/media/bg.d6ba5d57.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro2__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro2__wrp-process-txt {
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro2__wrp-imgs {
  position: relative;
}

.img-pro2__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 4.5%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  background-color: #48bf59;
}

.img-pro2__wrp-load-inner-img {
  position: absolute;
  margin-top: 2%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro2__wrp-tht-txt {
  color: white;
  margin-top: 10vh;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
}

.img-pro2__wrp-scan-txt {
  color: white;
  margin-top: 21vh;
  text-align: center;
  font-family: Lato;
  font-size: 3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
.img-pro2__wrp-process-txt {
  color: white;
  margin-top: 1.2vh;
  text-align: center;
  font-family: Lato;
  font-size: 2.4vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
}

.img-pro2__wrp-tub {
  position: relative;
}

.img-pro2__wrp-tub-img {
  position: absolute;
  top: 40%;
  margin-top: 5%;
  margin-left: 35%;
  margin-right: 35%;
  width: 30%;
}

.img-pro2__wrp-btn {
  position: relative;
  margin-top: 12vh;
}

.img-pro2__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  bottom: 20%;
  width: 80%;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.img-pro3__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  bottom: 30%;
  width: 80%;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

:root {
  --white: #ffffff;
  --headline-copy: #292a2d;
  --success-green: #48bf59;
}

.acc-scan {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.accbutton-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.accbutton-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.accbutton-pg__bg-dgn {
  width: 100%;
  height: 100%;
}
.accbutton__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-left: 50px;
  margin-top: 30px;
  border-radius: 22.5px;
  background-color: #d3d3d3;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
.accbutton-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.accbutton-pg__cnt-wrp {
  height: 58px;
  margin-top: 5.9vh;
}

.accbutton-pg__cnt-wrp > p {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #292a2d;
}

.accbutton__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-left: 40px;
  margin-top: 20px;
  border-radius: 22.5px;
  background-color: #d3d3d3;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
.acc-scan__bg-success {
  background-color: #48bf59;
}

.acc-scan__bg-no-success {
  background-color: #b5dfc2;
}

.acc-scan__vl {
  border-left: 6px dashed white;
  height: 100vh;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
  z-index: 2;
}

.acc-scan__hr {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.acc-scan__cnt {
  position: relative;
  width: 100%;
  height: 100%;
}

.acc-scan__top-sec {
  text-align: center;
  padding-top: 11.2vh;
}

.acc-scan__ph-setup {
  height: 42px;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #292a2d;
  color: var(--headline-copy);
}

.acc-scan__ph-finst {
  margin-top: 2.6vh;
  height: 100px;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.acc-scan__mid-sec {
  position: absolute;
  width: 100%;
  top: 43%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc-scan__mid-sec-su {
  position: absolute;
  width: 100%;
  top: 43%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc-scan__tar-rect {
  width: 100px;
  height: 100px;
  margin: auto;
  border: solid 3px #ffffff;
  border: solid 3px var(--white);
  background-color: #48bf59;
  background-color: var(--success-green);
}

.acc-scan__tar-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.acc-scan__tar-cir {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: solid 4px #ffffff;
  border: solid 4px var(--white);
  background-color: #48bf59;
  background-color: var(--success-green);
}

.acc-scan__tar-success {
  height: 45px;
  margin-top: 11px;
  font-size: 37.5px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  color: var(--white);
  margin-bottom: 2vh;
}

.acc-scan__angle-sec {
  position: absolute;
  width: 100vw;
  top: 68%;
}

.acc-scan__ph-angle {
  height: 100px;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #606060;
}

.acc-scan__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-scan__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-scan__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 2;
  background-color: white;
  border-radius: 50%;
}

.acc-scan__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-scan__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.so-md {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.so-md__header {
  position: absolute;
  margin-left: 5px;
}

.so-md__header-obj {
  margin-top: 40px;
}

.so-md__pr-sdbar {
  height: 50vh;
}

.so-md__pr-sdbar.collapsed {
  width: 0px;
  min-width: 0px;
}

.so-md__menu-true {
  position: absolute;
  margin-left: -75vw;
}

.so-md__menu {
  position: absolute;
  margin-left: -75vw;
}

.so-md__pr-sdbar-inner {
  background-color: gray;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  opacity: 0.8;
}

.so-md__pr-sdbar-layout {
  overflow-y: hidden;
  margin-top: -400px;
}

.so-md__pr-sdbar-layout ul {
  padding: 0 5px;
}

.so-md__menu-item {
  margin-top: 10px;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 1px;
}

.so-md__menu-item-1 {
  margin-top: 50px;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 1px;
}

.so-md__click {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

.so-md__img {
  position: relative;
}

.so-md__img_space {
  position: absolute;
  margin-top: 8vh;
  height: 65vh;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.so-md__btn {
  margin-top: 78vh;
}

.share-btn {
  width: 30%;
  height: 45px;
  display: inline-block;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: Lato;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 10%;
}

.logout-btn {
  width: 40%;
  height: 45px;
  display: inline-block;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  margin-left: 6%;
  cursor: pointer;
}

.so-md__updt {
  margin-top: 5vh;
}

.so-md__updt-txt {
  font-weight: 1000;
  font-family: Lato;
  font-size: 20px;
  color: black;
  text-align: center;
  letter-spacing: normal;
  text-decoration: underline;
}

.so-md__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.so-md__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.so-md__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.so-md__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.so-md__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

