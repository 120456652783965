.po-est__vid {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: relative;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}
.gif1 {
  width: 80vw;
  height: 80vh;
  margin-left: 12vw;
}
.gif2 {
  position: fixed;
  background-color: #000419;
  width: 100vw;
  height: 100vh;
}

.lod__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url(../../assets/images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
/* 
.lod__wrp-img {
  width: 100vw;
  height: 100vh;
}

.lod__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.lod__wrp-imgs {
  position: relative;
}

.lod__wrp-load-img {
  position: absolute;
  top: 40%;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}
.lod__wrp-load-inner-img {
  position: absolute;
  margin-top: 10%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.lod__wrp-tht-txt {
  color: white;
  margin-top: 11vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.5vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.lod__wrp-one-txt {
  position: relative;
  color: white;
  margin-top: 8vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.lod__wrp-sus-txt {
  color: white;
  position: relative;
  text-align: center;
  margin-top: 3vh;
  font-weight: 50;
  font-size: 17px;
  font-family: Lato;
  letter-spacing: normal;
}

.lod__wrp-btn {
  position: relative;
  margin-top: 17vh;
}

.lod__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  width: 80%;
  opacity: 0.5;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
} */

.lod-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.lod-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lod-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.lod-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.lod-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
