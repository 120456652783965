.img-pro1__wrp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro1__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro1__wrp-pr-txt {
  margin-top: 5vh;
  font-size: 23px;
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro1__wrp-imgs {
  position: relative;
}

.img-pro1__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 6%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.img-pro1__wrp-load-inner-img {
  position: absolute;
  margin-top: 6%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro1__wrp-tht-txt {
  color: white;
  margin-top: 10vh;
  text-align: center;
  font-family: Lato;
  font-size: 3.2vh;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1s;
  letter-spacing: 1;
}

.img-pro1__wrp-tub {
  position: relative;
}

.img-pro1__wrp-tub-img {
  position: absolute;
  font-size: 3.3vh;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-family: Lato;
  margin-top: 9%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.img-pro1__wrp-24-btubs {
  font-size: 3.3vh;
  color: #ffffff;
  font-weight: 400;
  font-family: Lato;
  margin-top: 70%;
  text-align: center;
}

.img-pro1__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.img-pro1__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-pro1__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.img-pro1__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.img-pro1__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
