.img-pro2__wrp {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.img-pro2__wrp-txt {
  color: white;
  margin-top: 10vh;
  font-size: 4.2vh;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.img-pro2__wrp-process-txt {
  font-family: Lato;
  letter-spacing: 1;
  text-align: center;
  color: #ffffff;
}

.img-pro2__wrp-imgs {
  position: relative;
}

.img-pro2__wrp-load-img {
  position: absolute;
  top: 30%;
  margin-top: 4.5%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  background-color: #48bf59;
}

.img-pro2__wrp-load-inner-img {
  position: absolute;
  margin-top: 2%;
  top: 1.7px;
  margin-left: 10%;
  margin-right: 10%;
  left: 0;
}

.img-pro2__wrp-tht-txt {
  color: white;
  margin-top: 10vh;
  font-family: Lato;
  font-size: 3.3vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
}

.img-pro2__wrp-scan-txt {
  color: white;
  margin-top: 21vh;
  text-align: center;
  font-family: Lato;
  font-size: 3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
.img-pro2__wrp-process-txt {
  color: white;
  margin-top: 1.2vh;
  text-align: center;
  font-family: Lato;
  font-size: 2.4vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
}

.img-pro2__wrp-tub {
  position: relative;
}

.img-pro2__wrp-tub-img {
  position: absolute;
  top: 40%;
  margin-top: 5%;
  margin-left: 35%;
  margin-right: 35%;
  width: 30%;
}

.img-pro2__wrp-btn {
  position: relative;
  margin-top: 12vh;
}

.img-pro2__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  bottom: 20%;
  width: 80%;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.img-pro3__wrp-btn-meet {
  position: absolute;
  width: 300px;
  height: 45px;
  margin-left: 10%;
  bottom: 30%;
  width: 80%;
  border-radius: 22.5px;
  background-color: #000000;
  font-family: Lato;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: white;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
