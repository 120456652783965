.rpwd-fd-pg__wrp {
  width: 100vw;
  height: 100%;
  position: relative;
}

.rpwd-fd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
  /* margin-top: 140px; */
  margin-top: 19vh;
}

.pwd-fd-pg__qa-wrp {
  margin-top: 26vh;
  /* margin-bottom: 83vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rpwd-fd-pg__wh-qs {
  width: 100%;
  height: 39px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.rpwd-fd-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  z-index: 20;
  margin-bottom: 10px;
}

.rpwd-fd-pg_ipt-blk {
  position: relative;
}

.rpwd-fd-pg_hde-pwd {
  height: 19.7px;
  width: 20px;
  z-index: 20;
  right: 4px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

.rpwd-fd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  border-bottom: solid 0.5px #292a2d;
  height: 53.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.rpwd-fd-pg__pwd-tl {
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.rpwd-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}
