.fpr-pg__wrp {
    width: 100vw;
    height: 100vh;
}

.fpr-pg__dgn-wrp {
    height: 37.2vh;
    position: relative;
}

.fpr-pg__bg-dgn {
    width: 100%;
    height: 100%;
}

.fpr-pg__logo {
    position: absolute;
    top: calc((32.7vh/2) - (71px/2));
    left: calc(50vw - (132px /2));
}

.fpr-pg__cnt-wrp {
     height: 58px;
     margin-top: 5.9vh;
}

.fpr-pg__cnt-wrp > p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #292a2d;
}