.cnp-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.cnp-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.cnp-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.cnp-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 2));
  left: calc(50vw - (132px / 2));
}

.cnp-pg__pop-up > p {
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #292a2d;
}

.cnp-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.cnp-pg__cnt-wrp.margin-top-field {
  /* margin-top: 140px; */
  margin-top: 19vh;
}

.cnp-pg__pop-wrap {
  position: relative;
}

.cnp-pg__pop-up {
  width: 224px;
  height: 120.9px;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #e0e0e0;
  background-color: #fffffe;
  position: absolute;
  border-radius: 5px;
  top: -135px;
  left: -80px;
}

.cnp-pg__pop-up::after {
  position: absolute;
  right: 10%;
  bottom: -7px;
  width: 0;
  height: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e0e0ff;
  z-index: 3;
}

.cnp-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  /* margin-bottom: 18px; */
  margin-bottom: 0px;
}

.cnp-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.cnp-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.page-field .cnp-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 15vh; */
  margin-top: 5vh;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
  padding: 11.2px 14px 21.7px 20px;
  border: solid 1px #d8d8d8;
  background-color: #fffffe;
  border-radius: 5px;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  right: 5px !important;
  left: auto !important;
}

.tooltip-info p {
  text-align: left;
  margin-bottom: 10px;
}

.tooltip-info p:last-child {
  margin-bottom: 0px;
}

.cnp-pg__info-alt:hover {
  cursor: pointer;
}
