.nme-fd-pg {
  height: 50vh;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
}

.nme-fd-pg__qa-wrp {
  margin-top: 10vh;
  /* margin-top: 26vh; */
  /* margin-bottom: 83vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nme-fd-pg__qs-wh {
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.nme-fd-pg__fs-nm,
.nme-fd-pg__ls-nm {
  border: none;
  outline: none;
  text-align: center;
  font-size: 22px;
  height: 32px;
  color: #606060;
  width: 325px;
  height: 60px;
  border-bottom: solid 0.5px #292a2d;
}

.nme-fd-pg__ls-nm {
  height: 49.5px;
}

.nme-fd-pg__fs-tl,
.nme-fd-pg__ls-tl {
  width: 274px;
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.nme-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 93.5px; */
  margin-top: 50px;
}
