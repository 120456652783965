.pose-est {
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100vh - 29px);
  width: 100vw;
}

.pose-est__wrp {
  height: 100%;
  width: 100%;
  position: relative;
}

.pose-est__webcam {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  width: 100vw;
  height: calc(100vh - 29px);
}
.po-est__vid {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.pose-est__canvas {
  padding: 55px 55px 105px 55px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  width: 100vw;
  height: 90vh;
}

.acc-scan__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-scan__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-scan__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.acc-scan__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-scan__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa__wh-pose-man {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px transparent;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}
.vck-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url("../../assets/images/bg-blur.jpg");
  background-size: cover;
}
.cam-bxa__cam-sec {
  width: 100vw;
  object-fit: cover;
  visibility: hidden;
  height: calc(100vh - 29px);
}
.cam-bxa__cam-sec1 {
  width: 100vw;
  object-fit: cover;
  visibility: hidden;
  height: calc(100vh - 29px);
}
.cam-bxa__cam-can {
  width: 10;
  object-fit: cover;
  height: 10;
}
.cam-bxa {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cam-bxa__cnt {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.cam-bxa__cam-sec {
  width: 100vw;
  object-fit: cover;
  height: calc(100vh - 29px);
}

.cam-bxa__wh-pose-man {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px transparent;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__bdr-con {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px white;
  width: calc(100vw - 100px);
  height: calc(100vh - 26vh);
}
.cam-bxa__bdr-con1 {
  position: absolute;
  top: 0;
  margin: 50px;
  object-fit: contain;
  border-radius: 12px;
  border: solid 3px white;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
}
.cam-bxa__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.cam-bxa__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cam-bxa__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.cam-bxa__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.cam-bxa__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
