.home__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.home__pg-cnt {
  display: flex;
  flex-direction: column;
}

.home__pg-bg-sec {
  height: 37.2vh;
  position: relative;
}

.home__pg-bg-top-dgn {
  width: 100%;
  height: 100%;
}

.home__pg-bg-instructs {
  position: absolute;
  top: 24.8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.home__pg-bg-hey {
  width: 276px;
  height: 54px;
  text-shadow: 0 2px 4px rgba(0, 7, 49, 0.5);
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.home__pg-bg-shop {
  width: 325px;
  height: 160px;
  margin: 10px 0 0;
  text-shadow: 0 1px 4px rgba(0, 7, 49, 0.5);
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.home__pg-three-min {
  position: absolute;
  top: calc(100vh - 69vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 64px;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #292a2d;
}

.home__pg-things-sec {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  top: 44vh;
}

.home__pg-abt-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
}

.home__pg-dgn-oval {
  display: inline-block;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 9px 0 rgba(173, 173, 173, 0.27);
  background-color: white;
}

.home__pg-shirt-ico {
  width: 34px;
  height: 34px;
  object-fit: contain;
}

.home__pg-abt-cnt {
  display: inline-block;
  width: 211px;
  height: 52px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #606060;
}

.home__pg-reduce-ico {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.home__pg-heart-ico {
  width: 33px;
  height: 30px;
  object-fit: contain;
}

.home__pg-btm-sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__pg-get-strt {
  width: 300px;
  height: 45px;
  margin-bottom: 2.3vh;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.home__pg-get-strt:active {
  position: relative;
  top: 0.8px;
}

.home__pg-sgn-in {
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 6.9vh;
  height: 22px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
