.acc-manage__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.acc-manage__rect-grp {
  width: 16px;
  height: 16px;
  margin: 0 0 9.5vh 83vw;
  object-fit: contain;
}
.acc-manage__rect-my-acc {
  width: 345px;
  height: 29px;
  margin: -50px 5px 32.5px;
  font-family: Lato;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: var(--headline-copy);
}
.acc-manage__rect-edit {
  width: 35vw;
  height: 5vh;
  margin: 35px 1px 0 110px;
  border-radius: 22.5px;
  font-family: Lato;
  color: white;
  font-size: 18px;
  font-weight: 800;
  background-color: #000000;
}
.acc-manage__rect-comm {
  margin-top: 15vh;
  margin-left: 3.3vw;
}
.acc-manage__rect-share {
  width: 39vw;
  height: 45px;
  border-radius: 22.5px;
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  color: white;
  letter-spacing: normal;
  background-color: #000000;
}
.acc-manage__rect-updt {
  width: 39vw;
  height: 45px;
  border-radius: 22.5px;
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  color: white;
  letter-spacing: normal;
  background-color: #000000;
}
.acc-manage__rect-save {
  width: 35vw;
  height: 5vh;
  margin: 35px 1px 0 110px;
  border-radius: 22.5px;
  font-family: Lato;
  color: white;
  font-size: 18px;
  font-weight: 800;
  border: none;
  background-color: #48bf59;
}
.textbox {
  padding: 0;
}
.accmanage-fd-pg__an-em {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-fn {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-ln {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 2px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-he {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 23px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-bd {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 10px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-em {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 31px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect-det-pw {
  border: none;
  outline: none;
  width: 195px;
  border-bottom: solid 0.5px #292a2d;
  margin-left: 2px;
  height: 28.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}
.acc-manage__rect {
  width: calc(100vw - 30px);
  height: 70vh;
  margin: 24px 15px;
  padding: 24px 0 56px;
  border-radius: 19px;
  box-shadow: 0 2px 11px 0 rgba(0, 7, 49, 0.5);
  background-color: var(--white);
}
.acc-manage__rect-det {
  width: 2vw;
  height: 4vh;
  margin: 38.5px 12px 25px 38px;
  font-family: Lato;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.29;
  letter-spacing: 0.78px;
  color: #606060;
}
.acc-manage-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.acc-manage-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acc-manage-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.acc-manage-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.acc-manage-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
