.so-md {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.so-md__header {
  position: absolute;
  margin-left: 5px;
}

.so-md__header-obj {
  margin-top: 40px;
}

.so-md__pr-sdbar {
  height: 50vh;
}

.so-md__pr-sdbar.collapsed {
  width: 0px;
  min-width: 0px;
}

.so-md__menu-true {
  position: absolute;
  margin-left: -75vw;
}

.so-md__menu {
  position: absolute;
  margin-left: -75vw;
}

.so-md__pr-sdbar-inner {
  background-color: gray;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  opacity: 0.8;
}

.so-md__pr-sdbar-layout {
  overflow-y: hidden;
  margin-top: -400px;
}

.so-md__pr-sdbar-layout ul {
  padding: 0 5px;
}

.so-md__menu-item {
  margin-top: 10px;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 1px;
}

.so-md__menu-item-1 {
  margin-top: 50px;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 1px;
}

.so-md__click {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

.so-md__img {
  position: relative;
}

.so-md__img_space {
  position: absolute;
  margin-top: 8vh;
  height: 65vh;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.so-md__btn {
  margin-top: 78vh;
}

.share-btn {
  width: 30%;
  height: 45px;
  display: inline-block;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: Lato;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 10%;
}

.logout-btn {
  width: 40%;
  height: 45px;
  display: inline-block;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 20px;
  font-family: Lato;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  margin-left: 6%;
  cursor: pointer;
}

.so-md__updt {
  margin-top: 5vh;
}

.so-md__updt-txt {
  font-weight: 1000;
  font-family: Lato;
  font-size: 20px;
  color: black;
  text-align: center;
  letter-spacing: normal;
  text-decoration: underline;
}

.so-md__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.so-md__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.so-md__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.so-md__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.so-md__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
