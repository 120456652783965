.re-np-pg__wrp {
  width: 100vw;
  height: 100vh;
}

.re-np-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.re-np-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.re-np-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.re-np-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.re-np-pg__wh-qs {
  margin-top: 5.9vh;
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
  margin-bottom: 18px;
}

.re-np-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 10px;
}

.re-np-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  height: 1px;
  border-bottom: solid 0.5px #292a2d;
  height: 32px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.re-np-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 17.2vh;
}
