.trm-cndn__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
}

.trm-cndn__wrp-pg {
  display: flex;
  flex-direction: column;
}
.trm-cndn__wrp-img__cls {
  width: 16px;
  height: 16px;
  margin: 0 0 9.5vh 83vw;
  object-fit: contain;
}
.trm-cndn__wrp-head {
  width: 345px;
  height: 29px;
  margin-top: -2vh;
  margin-bottom: 7vh;
  font-family: Roboto, arial, sans-serif;
  color: gray;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
}
.trm-cndn__txt {
  margin-bottom: 13px;
  padding-left: 3vw;
}
.trm-cndn__hd {
  margin-top: 20px;
  color: gray;
  font-family: Lato;
  font-size: 2vh;
  padding-left: 3vw;
  font-weight: 800;
}
.trm-cndn__lb {
  margin-top: 20px;
  color: gray;
  font-family: Lato;
  font-size: 2vh;
  font-weight: 800;
}
.trm-cndn__af-hd {
  margin-top: 15px;
  padding-left: 3vw;
  font-size: 19px;
}
.trm-cndn__wrp-scrl {
  width: calc(100vw - 30px);
  height: 80vh;
  margin: 30px 15px;
  padding: 24px 0 56px;
  border-radius: 19px;
  box-shadow: 0 2px 11px 0 rgba(0, 7, 49, 0.5);
  background-color: var(--white);
  overflow-x: hidden;
  overflow-y: scroll;
}

.trm-cndn__wrp-plcy__prgh {
  margin-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  letter-spacing: 2;
  color: gray;
  font-weight: 400;
  font-size: 14px;
  padding-left: 5px;
}

.trm-cndn__wrp-plcy__etta {
  margin-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  letter-spacing: 2;
  color: gray;
  font-weight: 400;
  font-size: 14px;
  padding-left: 4px;
}
.trm-cndn__check {
  margin-top: 20px;
  font-family: Roboto, arial, sans-serif;
  color: gray;
  font-size: 20px;
  padding-left: 3vw;
}
.trm-cndn__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0vh;
}

.trm-pg__bt-sec-wrp {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.trm-pg__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trm-pg__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.trm-pg__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.trm-pg__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
