.qu-expv {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.cam-bxa__cam-sec1 {
  width: 100vw;
  object-fit: cover;
  height: calc(100vh - 29px);
}
.qu-expv__cnt {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #393939;
}

.qu-expv__top-sec {
  height: 65px;
  width: 100%;
  padding-top: 33px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.qu-expv__btn-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qu-expv__lft-ar {
  height: 15px;
  width: 14px;
  margin-left: 1vw;
  margin-right: 0.2vw;
  margin-top: 0.1vh;
}

.qu-expv__rgt-ar {
  margin-left: 0.2vw;
  margin-right: 1vw;
  height: 15px;
  width: 14px;
  text-align: center;
}

.qu-expv__top-sec button {
  outline: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.qu-expv__top-sec button:first-child {
  color: grey;
}

.qu-expv__mid-sec {
  width: 100%;
  height: 50%;
  padding: 0 12.5px;
}

.qu-expv__quick-vid {
  object-fit: cover;
  width: calc(100vw - 25px);
  height: calc(100vh - (29px + 65px));
  border-radius: 55px;
}

.qu-expv__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.qu-expv__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qu-expv__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.qu-expv__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.qu-expv__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
