.scan-strt {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.scan-strt__cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-image: url("../../assets/images/peacock-background-lightened.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.scan-strt__top-sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.scan-strt__btn-wrp {
  height: 51px;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  padding-top: 33px;
}

.scan-strt__lft-ar {
  margin-top: 2px;
  height: 12.6px;
  width: 14px;
  margin-left: 24px;
  margin-right: 2px;
}

.scan-strt__back-btn {
  outline: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.scan-strt__back-btn > label {
  text-align: center;
}

.scan-strt__hw-wrks {
  margin-top: 40px;
  height: 42px;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__hw-spin {
  height: 128px;
  margin-top: 20px;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__mid-sec {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scan-strt__mid-inst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.scan-strt__mid-inst > p:first-child {
  width: 44px;
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 16px;
}

.scan-strt__mid-inst > p:last-child {
  margin-top: 13px;
  width: 64px;
  height: 30px;
  font-size: 25px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.scan-strt__scn-bwrp {
  position: absolute;
  bottom: 11.9vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scan-strt__scan-btn {
  border: none;
  outline: none;
  width: 300px;
  height: 45px;
  border-radius: 22.5px;
  background-color: #000000;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  cursor: pointer;
}

.scan-strt__btm-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 29px;
  background-color: #1f2021;
}

.scan-strt__bt-sec-cnt {
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-strt__bt-ball {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  z-index: 20;
  background-color: white;
  border-radius: 50%;
}

.scan-strt__bt-lf-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-left: 3.6px solid white;
  border-radius: 50%;
}

.scan-strt__bt-rg-arc {
  width: 22px;
  height: 14px;
  background-color: transparent;
  border-right: 3.6px solid white;
  border-radius: 50%;
}
