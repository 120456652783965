.gnd-pg__wrp {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.gnd-pg__dgn-wrp {
  height: 37.2vh;
  position: relative;
}

.gnd-pg__bg-dgn {
  width: 100%;
  height: 100%;
}

.gnd-pg__logo {
  position: absolute;
  top: calc((32.7vh / 2) - (71px / 1.1));
  left: calc(50vw - (132px / 2));
}

.gnd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 27.8vh;
}

.gnd-pg__wh-qs {
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.gnd-pg__info-alt {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-bottom: 4px;
}

.gnd-pg__an-wp {
  text-align: center;
}

.gnd-pg__an-wp > p > label {
  border: none;
  outline: none;
  background-color: transparent;
  width: 300px;
  height: 45px;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: #606060;
  /* padding: 5px 0; */
}

.gnd-pg__an-wp > p {
  margin-bottom: 4.2vh;
}

.gnd-pg__an-wp > p:last-child {
  margin-bottom: 0;
}

input[type="radio"] {
  display: none;
}

.gnd-pg__an-wp > p > label:first-child {
  margin-top: 0;
}

input[type="radio"]:checked + label {
  background-color: #48bf59;
  color: white;
  border-radius: 22.5px;
  display: inline-block;
}

.gnd-pg__cur-st-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gnd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10vh;
}

.gnd-pg__dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  position: absolute;
  left: calc(50vw - (95px / 2));
  bottom: 0;
}

.gnd-pg__dot,
.gnd-pg__act-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 0 4px;
}

.gnd-pg__act-dot {
  background-color: #000;
}
