.eml-fd-pg__wrp {
  width: 100vw;
  height: 100%;
  position: relative;
}

.eml-fd-pg__cnt-wrp {
  width: 100%;
  text-align: center;
}

.eml-fd-pg__wh-qs {
  margin-top: 10vh;
  /* margin-top: 150px; */
  width: 100%;
  height: 29px;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #292a2d;
}

.eml-fd-pg__an-em {
  border: none;
  outline: none;
  width: 325px;
  border-bottom: solid 0.5px #292a2d;
  height: 53.5px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.88px;
  text-align: center;
  color: #606060;
}

.eml-fd-pg__pwd-tl {
  height: 37px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.78px;
  text-align: center;
  color: #606060;
}

.eml-fd-pg__btn-wrp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: calc(190px - 36px - 50px); */
  margin-top: calc(190px - 55px - 50px);
}
