.splash {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.splash-cnt {
    background-image: url('../assets/images/splash.jpg');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    margin: auto;
}

.splash-logo {
    margin-top: 41.2vh;
    width: 252px;
    height: 123px;
}